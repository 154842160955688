<template>
  <Page title="Accounting export">
    <div class="flex flex-col items-center gap-y-4 p-4 border rounded max-w-[800px] mx-auto">
      <img class="quickbooks-logo" src="@/assets/quickbooks.svg" alt="QuickBooks logo" />
      <p>
        Our Integration with Quickbooks makes it easy for you to keep track of your invoices, taxes
        and paying your employees
      </p>
      <Quickbooks v-if="hasAccessToIntegration" />
      <template v-else>
        <p class="pb-3" v-if="!hasAccessToIntegration">
          Activate Payments before setting up your QuickBooks integration.
        </p>
        <btn @click="goToPayments" class="info"> Setup Payments</btn>
      </template>
    </div>
  </Page>
</template>

<script>
import PageMixin from '@/components/mixins/Page'
import Quickbooks from '@/components/bodies/Quickbooks.vue'

export default {
  mixins: [PageMixin],
  data() {
    return {
      hasAccessToIntegration: false
    }
  },
  created() {
    this.hasAccess()
  },
  methods: {
    async hasAccess() {
      this.hasAccess = null
      const { payload } = await this.$store.dispatch('ExportToken/hasAccess', {
        type: 'quickbooks'
      })
      const { value: verification } = payload
      this.hasAccessToIntegration = verification
    },
    goToPayments() {
      this.$router.push(`/${this.$route.params.scopeRoute}/payments`)
    }
  },
  components: {
    Quickbooks
  }
}
</script>

<style scoped>
.quickbooks-logo {
  max-width: 16em;
}
</style>
